import React from "react";
import "./Button.scss";
const Button = ({ children, onClick, disabled }) => {
  return (
    <button
      type="button"
      className="button !w-[120px] sm:w-auto !py-[8px] sm:p-auto !px-[20px]"
      disabled={disabled}
      onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
