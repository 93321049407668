import React from "react";

const Bitcoin = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32.7538 16.1292C32.7538 25.0371 25.5329 32.2584 16.6255 32.2584C7.71799 32.2584 0.49707 25.0371 0.49707 16.1292C0.49707 7.22128 7.71799 0 16.6255 0C25.5329 0 32.7538 7.22128 32.7538 16.1292ZM20.5002 10.1366C22.7438 10.9071 24.3849 12.0607 24.0629 14.2077C23.8291 15.7799 22.9543 16.5403 21.7921 16.8065C23.3866 17.6335 23.9301 19.2029 23.4251 21.1005C22.4664 23.8314 20.1874 24.0613 17.1562 23.4905L16.4202 26.4261L14.6434 25.9844L15.3693 23.0882C14.9087 22.9742 14.4379 22.8522 13.9529 22.7221L13.224 25.6325L11.4492 25.1908L12.1842 22.2491L8.60534 21.3496L9.48817 19.3226C9.48817 19.3226 10.7982 19.6685 10.78 19.6433C11.2832 19.7673 11.5069 19.4406 11.595 19.2238L13.5895 11.256C13.6117 10.8798 13.4811 10.4059 12.7613 10.2264C12.7886 10.2072 11.4705 9.90669 11.4705 9.90669L11.9433 8.01585L15.5272 8.90026L16.2562 5.99188L18.032 6.43358L17.3182 9.28448C17.7961 9.39238 18.276 9.5023 18.7427 9.61828L19.4514 6.78553L21.2282 7.22724L20.5002 10.1366ZM16.2488 14.9882C17.4591 15.3091 20.0928 16.0074 20.5519 14.1765C21.0202 12.3033 18.4615 11.7376 17.2087 11.4606L17.2086 11.4606L17.2085 11.4606C17.0666 11.4292 16.9414 11.4015 16.8393 11.3761L15.9545 14.9117C16.0388 14.9325 16.1378 14.9588 16.2488 14.9882ZM14.8775 20.6807C16.3271 21.0626 19.4976 21.8977 20.0021 19.8803C20.5185 17.8175 17.4445 17.1305 15.9446 16.7952L15.9441 16.7951C15.7767 16.7577 15.629 16.7247 15.508 16.6946L14.532 20.5913C14.6311 20.6158 14.7472 20.6464 14.8771 20.6806L14.8775 20.6807Z"
        fill="#F7931A"
      />
    </svg>
  );
};

export default Bitcoin;
