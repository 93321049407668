import React from "react";

const Ethelium = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16.37" cy="15.87" r="15.87" fill="#6481E7" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.5562 4.70361V20.724L23.6749 16.5157L16.5562 4.70361Z"
        fill="#C1CCF5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.43701 16.5157L16.5557 20.724V4.70361L9.43701 16.5157Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.5562 13.2802L9.43701 16.5155L16.5557 20.7238L23.6749 16.516L16.5562 13.2802Z"
        fill="#8299EC"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.5562 22.0718V27.8982L23.6795 17.8658L16.5562 22.0718Z"
        fill="#C1CCF5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.5562 13.2802L9.43701 16.5155L16.5557 20.7238L16.5562 13.2802Z"
        fill="#C1CCF5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.43701 17.8658L16.5557 27.8978V22.0718L9.43701 17.8658Z"
        fill="white"
      />
    </svg>
  );
};

export default Ethelium;
