import React from "react";

const Visa = () => {
  return (
    <svg
      width="50"
      height="16"
      viewBox="0 0 50 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.7505 15.5165H8.50987L5.32991 3.38475C5.17897 2.82669 4.8585 2.33333 4.38708 2.1008C3.21062 1.51646 1.91424 1.05141 0.5 0.81686V0.349787H7.33134C8.27417 0.349787 8.98129 1.05141 9.09914 1.86626L10.7491 10.6173L14.9877 0.349787H19.1104L12.7505 15.5165ZM21.4675 15.5165H17.4626L20.7604 0.349787H24.7653L21.4675 15.5165ZM29.9467 4.55143C30.0646 3.73456 30.7717 3.26748 31.5967 3.26748C32.8931 3.15021 34.3052 3.38476 35.4838 3.96708L36.1909 0.701619C35.0123 0.234547 33.716 0 32.5395 0C28.6524 0 25.8239 2.10081 25.8239 5.01648C25.8239 7.23457 27.8274 8.39921 29.2417 9.10083C30.7717 9.80043 31.361 10.2675 31.2431 10.9671C31.2431 12.0165 30.0646 12.4836 28.8881 12.4836C27.4739 12.4836 26.0596 12.1338 24.7653 11.5494L24.0582 14.8169C25.4724 15.3992 27.0025 15.6338 28.4167 15.6338C32.7752 15.749 35.4838 13.6502 35.4838 10.5C35.4838 6.53295 29.9467 6.30042 29.9467 4.55143ZM49.5 15.5165L46.32 0.349787H42.9044C42.1972 0.349787 41.4901 0.81686 41.2544 1.51646L35.3659 15.5165H39.4887L40.3116 13.3004H45.3772L45.8486 15.5165H49.5ZM43.4936 4.43416L44.6701 10.1502H41.3723L43.4936 4.43416Z"
        fill="white"
      />
    </svg>
  );
};

export default Visa;
