import React, { useState, useEffect } from "react";
import "./investor.css";
import Svg from "./Svg";
import Stars from "../../components/StarsBackground";
import {
  Linkedin,
  Instagram,
  Facebook,
  Twitter,
  Youtube,
  Logo,
} from "../LandingPage/Svg";
import { ChevronUp } from "lucide-react";
import Market from "./Market";
import Platform from "./Platform";
import Bussiness from "./Bussiness";
import Competitive from "./Competitive";
import Executive from "./Executive";
import Roadmap from "./Roadmap";
import Contact from "./Contact";
import Legal from "./Legal";
import Financial from "./Financial";
import Faq from "./Faq";

const InvestorRelations = () => {
  const [activeTab, setActiveTab] = useState("welcome");
  const [showPopup, setShowPopup] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const menuItems = [
    { id: "welcome", title: "Welcome" },
    { id: "executive-summary", title: "Executive Summary" },
    { id: "mission-vision", title: "Mission & Vision" },
    { id: "market-opportunity", title: "Market Opportunity" },
    { id: "platform-features", title: "Platform Features" },
    { id: "competitive-advantage", title: "Competitive Advantage" },
    { id: "legal", title: "Legal & Compliance" },
    { id: "faq", title: "Investor FAQ" },
    { id: "contact", title: "Contact Information" },
  ];

  const content = {
    welcome: {
      svg: "/assets/IR.gif",
      title: "EVERYONE.WORLD",
      subtitle: "The timeless meeting place",
      content:
        "A free platform for all, where every voice can rise, connect, and be seen across the globe. Make history with the world's only unified, chronological stream. A place for everyone, by everyone.",
    },
    "executive-summary": { content: <Executive /> },
    "mission-vision": {
      title: "Mission and Vision",
      subtitle: "Our Mission",
      subtitle1: "Our vision",
      content:
        "Is to create a universally accessible platform that empowers every individual, artists, entrepreneurs, creators, and influencers, to share their voice, reach a global audience, and monetize their work without barriers or platform fees. Everyone.world is dedicated to building an inclusive digital space where ideas, stories, and creativity flourish freely.",
      content1:
        "is to unite the world through a shared, chronological global stream that transcends borders, industries, and generations. We aim to become the enduring digital gathering placewhere every person can engage, collaborate, and contribute to a collective history, with each channel adding to a living legacy. By eliminating platform fees, introducing advanced features such as secure messaging and Spotlight ads, and empowering direct engagement with globalaudiences, we envision a future where connection and creativity are universally accessible. The Everyone Platform is designed to revolutionize how individuals share, build digital legacies, andsupport creators, fostering an open, transparent, and inclusive space where every voice matters and every contribution endures.",
    },
    "market-opportunity": { content: <Market /> },
    "platform-features": { content: <Platform /> },
    "business-model": { content: <Bussiness /> },
    "competitive-advantage": { content: <Competitive /> },
    roadmap: { content: <Roadmap /> },
    contact: { content: <Contact /> },
    legal: { content: <Legal /> },
    financial: { content: <Financial /> },
    faq: { content: <Faq /> },
  };

  const handlePopup = () => {
    setShowPopup(!showPopup);
  };

  useEffect(() => {
    const handleMouseMove = () => {
      setIsVisible(true);
      clearTimeout(window.hideHeaderFooter);
      window.hideHeaderFooter = setTimeout(() => setIsVisible(false), 2000);
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [activeTab]);

  return (
    <div className="min-h-screen flex flex-col   w-full">
      {/* Header */}
      <div
        className={`header px-8 py-6 border-b fixed top-0  bg-white z-50 w-full transition-opacity duration-500 ${
          isVisible ? "opacity-100" : "opacity-0"
        }`}
      >
        <div className="flex items-center gap-4">
          <span className="text-xl font-[redhatdisplay-s] text-[#182C69]">
            everyone
          </span>
        </div>
        <div className="font-[redhatdisplay-s] text-[#535354]">
          Investor Relations
        </div>
      </div>

      {/* Main Content */}
      <div className="flex md:max-w-7xl flex-1 mx-auto px-4 py-8  relative sm:w-[100%] ">
        <div className="mobile-content z-10 mx-auto">
          <button
            onClick={handlePopup}
            className="p-1 bg-white shadow flex items-center gap-1 border rounded-md w-[104px] h-[36px] justify-center"
          >
            <p className="text-[15px] font-[redhatdisplay-r]">Content</p>
            <ChevronUp
              onClick={handlePopup}
              className={`w-5 h-5 text-gray-500 transform transition-transform duration-300 ease-in-out ${
                showPopup ? "" : "rotate-180"
              }`}
            />
          </button>
        </div>
        <div className=" md:w-2/3  md:px-10 px-2  sm:w-[100%]">
          {activeTab === "welcome" && (
            <div className="mobile-width animate-fade-slide">
              <div className="flex gap-[60px] items-start flex-col mt-12">
                <img src={content[activeTab]?.svg} />
              </div>
              {/* <h1 className="second md:text-[48px]  text-[28px] font-[redhatdisplay-s]  md:font-[redhatdisplay-s] text-[#4D4E51] leading-[55px]">
                {content[activeTab]?.title}
              </h1>
              <h2 className="text-[28px]  md:text-[48px]  font-[redhatdisplay-s] text-[#4D4E51] leading-[55px]">
                {content[activeTab].subtitle}
              </h2>
              <p className=" text-[#202B39]  mt-6 text-[18px] md:text-[28px] font-[redhatdisplay-r]">
                {content[activeTab]?.content1}
              </p> */}
            </div>
          )}
          {activeTab === "executive-summary" && (
            <div className="animate-fade-slide mt-12 pb-20">
              {content["executive-summary"].content}{" "}
              {/* Render the Market component */}
            </div>
          )}
          {activeTab === "mission-vision" && (
            <div className="animate-fade-slide mt-20 pb-20">
              <h1 className="second md:text-[48px]  text-[28px] font-[redhatdisplay-s]  md:font-[redhatdisplay-s] text-[#4D4E51] leading-[55px]">
                {content[activeTab]?.title}
              </h1>
              <h2 className="text-[28px]  md:text-[38px]  font-[redhatdisplay-s] text-[#4D4E51] leading-[55px]">
                {content[activeTab].subtitle}
              </h2>
              <p className=" text-[#202B39]  mt-6 text-[18px] md:text-[18px] font-[redhatdisplay-r]">
                {content[activeTab]?.content}
              </p>
              <h2 className="pt-[30px] text-[28px]  md:text-[38px]  font-[redhatdisplay-s] text-[#4D4E51] leading-[55px]">
                {content[activeTab].subtitle1}
              </h2>
              <p className=" text-[#202B39]  mt-6 text-[18px] md:text-[18px] font-[redhatdisplay-r]">
                {content[activeTab]?.content1}
              </p>
            </div>
          )}
          {activeTab === "market-opportunity" && (
            <div className="animate-fade-slide mt-12 pb-20">
              {content["market-opportunity"].content}{" "}
              {/* Render the Market component */}
            </div>
          )}
          {activeTab === "platform-features" && (
            <div className="animate-fade-slide mt-12 pb-20">
              {content["platform-features"].content}{" "}
              {/* Render the Market component */}
            </div>
          )}
          {activeTab === "business-model" && (
            <div className="animate-fade-slide mt-12 pb-20">
              {content["business-model"].content}{" "}
              {/* Render the Market component */}
            </div>
          )}
          {activeTab === "competitive-advantage" && (
            <div className="animate-fade-slide mt-12 pb-20">
              {content["competitive-advantage"].content}{" "}
              {/* Render the Market component */}
            </div>
          )}
          {activeTab === "roadmap" && (
            <div className="animate-fade-slide mt-12 pb-20">
              {content["roadmap"].content} {/* Render the Market component */}
            </div>
          )}
          {activeTab === "legal" && (
            <div className="animate-fade-slide mt-12 pb-20">
              {content["legal"].content} {/* Render the Market component */}
            </div>
          )}
          {activeTab === "financial" && (
            <div className="animate-fade-slide mt-12 pb-20">
              {content["financial"].content} {/* Render the Market component */}
            </div>
          )}
          {activeTab === "faq" && (
            <div className="animate-fade-slide mt-12 pb-20">
              {content["faq"].content} {/* Render the Market component */}
            </div>
          )}
          {activeTab === "contact" && (
            <div className="animate-fade-slide mt-12 pb-20">
              {content["contact"].content} {/* Render the Market component */}
            </div>
          )}
        </div>

        {/* Right Navigation Menu */}
        <div className={`${showPopup ? "block popup" : "menuhidden"} w-1/3`}>
          <div className="mobile-right sticky top-28 border rounded-xl p-6 shadow-md">
            <nav className="space-y-2">
              {menuItems.map((item) => (
                <button
                  key={item.id}
                  onClick={() => {
                    setActiveTab(item.id);
                    setShowPopup(false);
                  }}
                  className={`block w-full text-left px-4 py-2 rounded-lg transition-colors duration-200 ${
                    activeTab === item.id
                      ? "bg-blue-50 text-blue-600 border-l-4 border-blue-600"
                      : "hover:bg-gray-50 text-gray-600"
                  }`}
                >
                  {item.title}
                </button>
              ))}
            </nav>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer
        className={`footer bg-white border-t py-6 px-8 md:px-8 fixed bottom-0  w-full transition-opacity duration-500 ${
          isVisible ? "opacity-100" : "opacity-0"
        }`}
      >
        <div className="sm:flex-col sm:items-start sm:gap-10 md:gap-0 md:flex md:flex-row md:items-left justify-between text-sm text-gray-500">
          <div className="flex items-center gap-2">
            <Logo />
            <span className="font-[redhatdisplay-s] text-[#182C69] text-[34px]">
              everyone
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default InvestorRelations;
