"use client";
import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import "./index.css";

const StarsBackground = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    let mouseX = 0;
    let mouseY = 0;

    const handleMouseMove = (event) => {
      mouseX = (event.clientX / window.innerWidth) * 2 - 1;
      mouseY = -(event.clientY / window.innerHeight) * 2 + 1;
    };

    (async () => {
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(
        60,
        window.innerWidth / window.innerHeight,
        1,
        1000
      );
      camera.position.z = 1;
      camera.rotation.x = Math.PI / 2;

      const renderer = new THREE.WebGLRenderer();
      renderer.setSize(window.innerWidth, window.innerHeight);
      mountRef.current.appendChild(renderer.domElement);

      // Create the star field
      const starCount = 6000;
      const positions = new Float32Array(starCount * 3); // x, y, z for each star
      const velocities = new Float32Array(starCount);
      const accelerations = new Float32Array(starCount);

      for (let i = 0; i < starCount; i++) {
        positions[i * 3] = Math.random() * 600 - 300; // x
        positions[i * 3 + 1] = Math.random() * 600 - 300; // y
        positions[i * 3 + 2] = Math.random() * 600 - 300; // z
        velocities[i] = 0;
        accelerations[i] = 0.001;
      }

      const starGeometry = new THREE.BufferGeometry();
      starGeometry.setAttribute(
        "position",
        new THREE.BufferAttribute(positions, 3)
      );

      const textureLoader = new THREE.TextureLoader();
      const starUrl = "https://i.postimg.cc/jSNbgB4k/star.png";
      const sprite = textureLoader.load(starUrl);

      const starMaterial = new THREE.PointsMaterial({
        color: 0xaaaaaa,
        size: 1.5,
        map: sprite,
        blending: THREE.AdditiveBlending,
        transparent: true,
      });

      const stars = new THREE.Points(starGeometry, starMaterial);
      scene.add(stars);

      // Animation function
      const animate = () => {
        const positions = starGeometry.attributes.position.array;

        for (let i = 0; i < starCount; i++) {
          velocities[i] += accelerations[i];
          positions[i * 3 + 1] -= velocities[i]; // Move along y-axis

          // Apply the parallax effect
          positions[i * 3] += mouseX * 0.01; // Adjust sensitivity
          positions[i * 3 + 2] += mouseY * 0.01; // Adjust sensitivity

          // Wrap around logic to keep stars in bounds
          if (positions[i * 3] > 300) positions[i * 3] = -300; // Left to right
          else if (positions[i * 3] < -300) positions[i * 3] = 300; // Right to left

          if (positions[i * 3 + 2] > 300)
            positions[i * 3 + 2] = -300; // Forward to back
          else if (positions[i * 3 + 2] < -300) positions[i * 3 + 2] = 300; // Back to forward

          if (positions[i * 3 + 1] < -200) {
            positions[i * 3 + 1] = 200;
            velocities[i] = 0;
          }
        }

        starGeometry.attributes.position.needsUpdate = true;
        stars.rotation.y += 0.002;

        renderer.render(scene, camera);
        requestAnimationFrame(animate);
      };

      animate();

      // Handle window resize
      const handleWindowResize = () => {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
      };

      // Add mousemove listener
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("resize", handleWindowResize);

      // Clean up on component unmount
      return () => {
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("resize", handleWindowResize);
        mountRef.current.removeChild(renderer.domElement);
      };
    })();
  }, []);

  return <div ref={mountRef} className={"stars-background"} />;
};

export default StarsBackground;
