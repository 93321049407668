import React, { useState } from "react";
import {
  Linkedin,
  Instagram,
  Facebook,
  Twitter,
  Youtube,
  Logo,
  Ico,
} from "../LandingPage/Svg";
import Stars from "../../components/StarsBackground";
import Popup from "../../components/popup";
import LM from "../../assets/svg/Content";
import { GoArrowLeft } from "react-icons/go";
import { GoArrowRight } from "react-icons/go";
import "./ico.css";
export const coins = [
  {
    name: "Bitcoin",
    image: "/assets/Dollar.png",
    description:
      "Lorem ipsum dolor sit amet consectetur. Lorem auctor arcu cursus faucibus massa adipiscing non. Leo venenatis in non nulla vel sit quam. Semper id id faucibus dignissim etiam praesent. Arcu aliquam velit nisi aliquam lobortis. Turpis sed dapibus id elit tincidunt tempus.",
  },
  {
    name: "Ethereum",
    image: "/assets/ethereum.png",
    description: "A decentralized platform for smart contracts.",
  },
  {
    name: "Ripple",
    image: "/assets/ripple.png",
    description: "A digital payment protocol.",
  },
  {
    name: "Litecoin",
    image: "/assets/litecoin.png",
    description: "A peer-to-peer cryptocurrency.",
  },
  {
    name: "Cardano",
    image: "/assets/cardano.png",
    description: "A blockchain platform for change makers.",
  },
  {
    name: "Polkadot",
    image: "/assets/polkadot.png",
    description: "A network protocol for cross-chain transfers.",
  },
  {
    name: "Solana",
    image: "/assets/solana.png",
    description: "A fast blockchain for decentralized apps.",
  },
  {
    name: "Chainlink",
    image: "/assets/chainlink.png",
    description: "A decentralized oracle network.",
  },
  {
    name: "Stellar",
    image: "/assets/stellar.png",
    description: "A blockchain for financial products.",
  },
  {
    name: "Dogecoin",
    image: "/assets/dogecoin.png",
    description: "A fun and friendly cryptocurrency.",
  },
];

const App = () => {
  const [selectedCoin, setSelectedCoin] = useState(0);
  const [hoveredCoin, setHoveredCoin] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const handleArrowClick = (direction) => {
    if (direction === "left") {
      setSelectedCoin((prev) => (prev === 0 ? coins.length - 1 : prev - 1));
    } else {
      setSelectedCoin((prev) => (prev === coins.length - 1 ? 0 : prev + 1));
    }
  };

  return (
    <div className="overflow-hidden  text-white">
      {/* Navbar */}
      <header className="flex justify-between items-center px-6 py-5 bg-[#272D3F]">
        <div className="flex flex-col items-start">
          <div className="text-3xl font-[redhatdisplay-s] ">everyone</div>
          <div className="text-lg font-[redhatdisplay-s]">
            Lorem Ipsum dolor
          </div>
        </div>
        <div className="flex flex-col items-center gap-1">
          <button
            className="px-16 py-2 disabled:opacity-50 bg-gradient-to-r from-[#00DDFF] to-[#4093FF] hover:brightness-110 rounded-full"
            onClick={() => setShowPopup(true)}
          >
            Buy Now!
          </button>
          <span className="text-[16px] font-[redhatdisplay-b] font-bold">
            Pre-Sale: $0.01 per EVRY
          </span>
        </div>
      </header>

      {/* Coins Navigation */}
      <div className="text-center mt-10">
        <div className="flex gap-4 p-4 absolute right-0 top-28">
          <div className="spinning-border">
            <div className="spinning-border-inner">
              <button className="px-6 py-2 rounded-full text-white">
                WhitePaper
              </button>
            </div>
          </div>

          <div className="spinning-border">
            <div className="spinning-border-inner">
              <button className="px-6 py-2 rounded-full text-white">
                OnePager
              </button>
            </div>
          </div>
        </div>
        <div className=" mb-4 flex items-center justify-center">
          <svg
            width="103"
            height="103"
            viewBox="0 0 103 103"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_8_182)">
              <path
                d="M52.0359 103.001L42.2266 95.3917L52.0293 88.4776C52.183 88.4776 52.3435 88.4776 52.4973 88.4776C52.6511 88.4776 52.8049 88.4709 52.9587 88.4643C59.9196 88.2904 66.3923 86.1573 71.8486 82.5933C77.305 79.0293 81.7449 74.0411 84.6336 68.1635C85.8238 65.7496 86.7466 63.1886 87.3751 60.5072C88.0037 57.8258 88.338 55.0375 88.338 52.1689C88.338 42.1388 84.2792 33.0583 77.7062 26.4853C71.1331 19.9123 62.0526 15.8468 52.0226 15.8535V8.59171V1.32996C66.0646 1.32996 78.776 7.02033 87.9769 16.2212C97.1912 25.4021 102.882 38.1135 102.882 52.1555C102.882 66.0505 97.3049 78.6482 88.2645 87.829C79.224 97.0098 66.7266 102.767 52.8785 102.988C52.738 102.988 52.5976 102.994 52.4572 102.994C52.3235 102.994 52.183 103.001 52.0359 103.001Z"
                fill="url(#paint0_linear_8_182)"
              />
              <path
                d="M84.7119 33.9925V34.0059C84.7186 34.367 84.7119 34.728 84.6985 35.0891C84.6852 35.4502 84.6651 35.8046 84.6451 36.159C84.09 44.6778 80.2653 52.3074 74.4144 57.8105C68.5636 63.3136 60.6933 66.6837 52.034 66.6904L52.0274 66.6837L52.0207 66.6771C43.3614 66.6771 35.4845 63.307 29.6403 57.8105C23.7961 52.314 19.9647 44.6845 19.4097 36.159C22.305 30.2814 24.8794 19.4222 30.3357 15.8649C35.7921 12.3076 44.1371 16.0387 51.0979 15.8649C46.2969 16.1123 41.9906 18.2186 38.8813 21.475C35.772 24.7314 33.8663 29.1447 33.8663 33.9992C33.8663 39.0209 35.8991 43.5545 39.1822 46.8376C42.4654 50.1208 47.0057 52.1469 52.0207 52.1469L52.0274 52.1536L52.034 52.1603C57.0491 52.1669 61.5893 50.1342 64.8725 46.8443C68.1623 43.5545 70.1951 39.0142 70.1951 33.9925H77.4569H84.7119Z"
                fill="url(#paint1_linear_8_182)"
              />
              <path
                d="M52.0268 1.31042L61.5486 9.19404L52.0335 15.8339C51.8797 15.8339 51.7192 15.8339 51.5654 15.8339C51.4116 15.8406 51.2578 15.8406 51.104 15.8473C44.1432 16.0212 37.6704 18.1542 32.2141 21.7182C26.7578 25.2822 22.3178 30.2705 19.4291 36.1481C18.2389 38.562 17.3161 41.123 16.6876 43.8044C16.059 46.4857 15.7113 49.2808 15.7113 52.156C15.7113 62.1861 19.7702 71.2666 26.3432 77.8396C32.9162 84.4127 41.9967 88.4782 52.0268 88.4715V95.7332V102.995C37.9847 102.995 25.2733 97.3046 16.0724 88.1037C6.87153 78.9095 1.18115 66.1981 1.18115 52.156C1.18115 38.2611 6.75785 25.6634 15.7983 16.4825C24.8387 7.3017 37.3361 1.54446 51.1776 1.3238C51.318 1.3238 51.4584 1.31711 51.5988 1.31711C51.7392 1.31042 51.8797 1.31042 52.0268 1.31042Z"
                fill="url(#paint2_linear_8_182)"
              />
              <path
                d="M84.7103 33.9883L77.4485 37.4253L70.1868 33.9883C70.1801 28.9733 68.1473 24.4397 64.8642 21.1498C61.581 17.86 57.0407 15.8339 52.0257 15.8339L52.019 8.56549V1.31042C52.1662 1.31042 52.3066 1.31042 52.447 1.31711C52.5874 1.3238 52.7278 1.3238 52.8683 1.3238C61.7014 1.55115 69.6585 5.27563 75.4091 11.1666C81.1596 17.0576 84.7036 25.1017 84.7103 33.9883Z"
                fill="url(#paint3_linear_8_182)"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_8_182"
                x1="72.5394"
                y1="88.1445"
                x2="72.5396"
                y2="17.6288"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="white" />
                <stop
                  offset="0.2074"
                  stop-color="white"
                  stop-opacity="0.7926"
                />
                <stop
                  offset="0.4343"
                  stop-color="white"
                  stop-opacity="0.5657"
                />
                <stop
                  offset="0.6703"
                  stop-color="white"
                  stop-opacity="0.3297"
                />
                <stop
                  offset="0.9112"
                  stop-color="white"
                  stop-opacity="0.0888"
                />
                <stop offset="1" stop-color="white" stop-opacity="0" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_8_182"
                x1="57.5306"
                y1="46.2701"
                x2="32.2872"
                y2="21.0267"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="white" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_8_182"
                x1="31.3613"
                y1="16.3811"
                x2="31.3613"
                y2="86.9221"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="white" />
                <stop offset="0.2137" stop-color="white" />
                <stop offset="0.4365" stop-color="white" />
                <stop offset="0.6634" stop-color="white" />
                <stop offset="0.8919" stop-color="white" />
                <stop offset="1" stop-color="white" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_8_182"
                x1="76.8467"
                y1="26.3177"
                x2="58.9587"
                y2="9.65221"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="white" />
                <stop offset="0.2532" stop-color="white" />
                <stop offset="0.7744" stop-color="white" />
                <stop offset="1" stop-color="white" />
              </linearGradient>
              <clipPath id="clip0_8_182">
                <rect width="103" height="103" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className="flex justify-center items-center gap-6 relative">
          {coins.map((coin, index) => (
            <div
              key={index}
              onClick={() => setSelectedCoin(index)}
              onMouseEnter={() => setHoveredCoin(index)}
              onMouseLeave={() => setHoveredCoin(null)}
              className={`relative transition-transform duration-300 cursor-pointer ${
                selectedCoin === index ? "scale-125" : "scale-100"
              }`}
            >
              <img
                src={coin.image}
                alt={coin.name}
                className={`w-8 h-8 md:w-16 md:h-16 p-2 rounded-full border border-white   ${
                  selectedCoin === index
                    ? "border-4 border-blue-600"
                    : "border-2 border-white"
                }`}
              />
              {hoveredCoin === index && (
                <div className="absolute  text-sm text-gray-300 w-full text-center">
                  {coin.name}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Main Content */}
      <main className="flex justify-center items-center py-16 ">
        <div className="w-11/12 lg:h-[calc(55vh-100px)] md:h-[30%] md:max-w-[1350px]  md:w-11/12 lg:w-11/12 bg-[#272D3F] p-6 rounded-[18px] shadow-lg  border-2 border-[#7F8EBA] relative">
          <button
            onClick={() => handleArrowClick("left")}
            className="absolute top-1/2 flex items-center justify-center  -left-8 -translate-y-1/2 p-2 bg-[#272D3F] hover:bg-gray-500 rounded-full border-2 border-[#7F8EBA] w-[63px] h-[40px]"
          >
            <GoArrowLeft fontSize="26px" />
          </button>
          <div className="text-start flex flex-col gap-[17px] px-8 py-6">
            <LM />
            <h2 className="text-[63.66px] font-bold mb-2 font-[redhatdisplay-b]">
              {coins[selectedCoin].name}
            </h2>

            <p className="text-white clamp  font-[redhatdisplay-r]">
              {coins[selectedCoin].description}
            </p>
          </div>
          <button
            onClick={() => handleArrowClick("right")}
            className="absolute flex items-center justify-center top-1/2 -right-8 -translate-y-1/2 p-2 hover:bg-gray-500 rounded-full bg-[#272D3F] border-2 border-[#7F8EBA] w-[63px] h-[40px]"
          >
            <GoArrowRight fontSize="26px" />
          </button>
        </div>
      </main>

      {/* Footer */}
      <footer className="  py-5 px-6 md:px-6 bg-[#272D3F] w-full fixed bottom-0">
        <div className="sm:flex-col sm:items-start sm:gap-10 md:gap-0 md:flex md:flex-row md:items-center justify-between text-sm text-gray-500 ">
          <div className="flex items-center gap-2">
            <div className="sm:flex-col sm:items-start sm:gap-10 md:gap-0 md:flex md:flex-row md:items-left justify-between text-sm text-gray-500">
              <div className="flex items-center gap-2">
                <Logo />
                <span className="font-[redhatdisplay-s] text-[#ffff] text-[34px]">
                  everyone
                </span>
              </div>
            </div>
            {/* <span className="font-[redhatdisplay-s] text-[#182C69]  text-[34px]">
              everyone
            </span> */}
          </div>
          <div className="flex gap-6 flex-wrap ">
            <a href="#" className="hover:text-white">
              Partnerships Overview
            </a>
            <a href="#" className="hover:text-white">
              Platform Features
            </a>
            <a href="#" className="hover:text-white">
              Media Kit
            </a>
            <a href="#" className="hover:text-white">
              Terms of Service
            </a>
            <a href="#" className="hover:text-white">
              Privacy Policy
            </a>
            {/* <a
              href="/privacy-policy"
              className="hover:text-gray-700 hover:underline"
            >
              Everyone Policies
            </a> */}
          </div>
        </div>
      </footer>
      <Stars />
      {showPopup && <Popup setShowPopup={setShowPopup} />}
    </div>
  );
};

export default App;
