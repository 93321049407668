import React from "react";

const Content = () => {
  return (
    <svg
      width="123"
      height="123"
      viewBox="0 0 123 123"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_25_1132)">
        <path
          d="M62.1399 123.001L50.4258 113.914L62.1319 105.658C62.3156 105.658 62.5072 105.658 62.6909 105.658C62.8745 105.658 63.0582 105.65 63.2418 105.642C71.5543 105.434 79.2839 102.887 85.7997 98.6308C92.3155 94.3748 97.6176 88.4179 101.067 81.399C102.489 78.5164 103.59 75.4581 104.341 72.2561C105.092 69.0541 105.491 65.7243 105.491 62.2987C105.491 50.3211 100.644 39.4773 92.7946 31.628C84.9453 23.7787 74.1015 18.9237 62.1239 18.9317V10.2599V1.58813C78.8926 1.58813 94.0722 8.38344 105.06 19.3709C116.063 30.3344 122.858 45.5141 122.858 62.2827C122.858 78.8757 116.199 93.9196 105.403 104.883C94.6072 115.847 79.6831 122.722 63.146 122.985C62.9783 122.985 62.8106 122.993 62.643 122.993C62.4832 122.993 62.3156 123.001 62.1399 123.001Z"
          fill="url(#paint0_linear_25_1132)"
        />
        <path
          d="M101.161 40.5929V40.6089C101.169 41.0401 101.161 41.4713 101.145 41.9025C101.129 42.3337 101.105 42.7569 101.081 43.1801C100.418 53.3531 95.8509 62.4641 88.864 69.0358C81.877 75.6075 72.4786 79.632 62.1379 79.64L62.1299 79.632L62.122 79.624C51.7813 79.624 42.3748 75.5995 35.3959 69.0358C28.4169 62.4721 23.8415 53.3611 23.1787 43.1801C26.6363 36.1612 29.7105 23.1935 36.2263 18.9454C42.7422 14.6973 52.7075 19.153 61.02 18.9454C55.2867 19.2408 50.1443 21.7561 46.4313 25.6449C42.7182 29.5336 40.4425 34.8038 40.4425 40.6009C40.4425 46.5977 42.8699 52.0116 46.7906 55.9323C50.7113 59.853 56.1331 62.2724 62.122 62.2724L62.1299 62.2804L62.1379 62.2884C68.1267 62.2964 73.5486 59.8689 77.4693 55.9403C81.3979 52.0116 83.8254 46.5897 83.8254 40.5929H92.4972H101.161Z"
          fill="url(#paint1_linear_25_1132)"
        />
        <path
          d="M62.1292 1.56494L73.5 10.9794L62.1372 18.9085C61.9535 18.9085 61.7619 18.9085 61.5782 18.9085C61.3946 18.9165 61.2109 18.9165 61.0273 18.9245C52.7148 19.1321 44.9852 21.6794 38.4694 25.9354C31.9536 30.1915 26.6515 36.1483 23.2019 43.1672C21.7806 46.0498 20.6786 49.1081 19.928 52.3101C19.1774 55.5121 18.7622 58.8499 18.7622 62.2835C18.7622 74.2611 23.6092 85.1049 31.4585 92.9542C39.3078 100.804 50.1516 105.658 62.1292 105.65V114.322V122.994C45.3605 122.994 30.1809 116.199 19.1934 105.211C8.20595 94.2318 1.41064 79.0522 1.41064 62.2835C1.41064 45.6905 8.0702 30.6466 18.866 19.6831C29.6619 8.71957 44.586 1.84442 61.1151 1.58091C61.2828 1.58091 61.4505 1.57293 61.6182 1.57293C61.7858 1.56494 61.9535 1.56494 62.1292 1.56494Z"
          fill="url(#paint2_linear_25_1132)"
        />
        <path
          d="M101.159 40.588L92.4874 44.6924L83.8156 40.588C83.8076 34.5992 81.3801 29.1853 77.4595 25.2567C73.5388 21.328 68.1169 18.9085 62.1281 18.9085L62.1201 10.2288V1.56494C62.2958 1.56494 62.4635 1.56494 62.6312 1.57293C62.7988 1.58091 62.9665 1.58091 63.1342 1.58091C73.6825 1.8524 83.1848 6.30009 90.0519 13.335C96.9191 20.3698 101.151 29.9759 101.159 40.588Z"
          fill="url(#paint3_linear_25_1132)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_25_1132"
          x1="86.6246"
          y1="105.26"
          x2="86.6248"
          y2="21.0518"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#247FFF" />
          <stop offset="0.2074" stop-color="#2283FE" stop-opacity="0.7926" />
          <stop offset="0.4343" stop-color="#1C91FC" stop-opacity="0.5657" />
          <stop offset="0.6703" stop-color="#13A6F9" stop-opacity="0.3297" />
          <stop offset="0.9112" stop-color="#06C5F4" stop-opacity="0.0888" />
          <stop offset="1" stop-color="#00D2F2" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_25_1132"
          x1="68.7017"
          y1="55.2546"
          x2="38.5567"
          y2="25.1096"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00D48D" />
          <stop offset="1" stop-color="#00D48D" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_25_1132"
          x1="37.4511"
          y1="19.562"
          x2="37.4511"
          y2="103.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00D2F2" />
          <stop offset="0.2137" stop-color="#02CEF3" />
          <stop offset="0.4365" stop-color="#07C1F5" />
          <stop offset="0.6634" stop-color="#10ACF8" />
          <stop offset="0.8919" stop-color="#1D8FFC" />
          <stop offset="1" stop-color="#247FFF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_25_1132"
          x1="91.7686"
          y1="31.4279"
          x2="70.4073"
          y2="11.5265"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00D48D" />
          <stop offset="0.2532" stop-color="#00D4A2" />
          <stop offset="0.7744" stop-color="#00D3D9" />
          <stop offset="1" stop-color="#00D2F2" />
        </linearGradient>
        <clipPath id="clip0_25_1132">
          <rect width="123" height="123" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Content;
