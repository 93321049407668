import React from "react";

const Paypal = () => {
  return (
    <svg
      width="41"
      height="36"
      viewBox="0 0 41 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.30001 24.4819H2.18878C1.97588 24.4819 1.7948 24.6366 1.7616 24.8467L0.503264 32.8248C0.478253 32.9823 0.60017 33.1242 0.759855 33.1242H2.24519C2.45809 33.1242 2.63916 32.9695 2.67236 32.7589L3.01175 30.6072C3.04449 30.3964 3.22601 30.2419 3.43847 30.2419H4.42338C6.47286 30.2419 7.6556 29.2501 7.96465 27.2848C8.10381 26.425 7.97054 25.7495 7.56781 25.2762C7.1257 24.7568 6.34128 24.4819 5.30001 24.4819ZM5.65896 27.3958C5.48885 28.5122 4.63584 28.5122 3.81106 28.5122H3.34157L3.67094 26.4271C3.69049 26.3012 3.79967 26.2084 3.92706 26.2084H4.14224C4.70407 26.2084 5.23412 26.2084 5.50801 26.5286C5.67117 26.7198 5.72128 27.0036 5.65896 27.3958ZM14.6002 27.36H13.1103C12.9834 27.36 12.8737 27.4528 12.8541 27.5789L12.7882 27.9956L12.684 27.8446C12.3615 27.3764 11.6423 27.22 10.9244 27.22C9.27792 27.22 7.87181 28.4669 7.59791 30.2162C7.4556 31.0886 7.65791 31.9229 8.15286 32.5048C8.60697 33.0398 9.25665 33.2627 10.0295 33.2627C11.356 33.2627 12.0918 32.4097 12.0918 32.4097L12.0253 32.8238C12.0002 32.9821 12.1221 33.124 12.2809 33.124H13.623C13.8365 33.124 14.0165 32.9693 14.0501 32.7587L14.8554 27.6594C14.8809 27.5025 14.7594 27.36 14.6002 27.36ZM12.5234 30.2598C12.3796 31.1109 11.704 31.6823 10.8426 31.6823C10.4099 31.6823 10.064 31.5436 9.84213 31.2806C9.62192 31.0196 9.53813 30.6478 9.60823 30.2339C9.74255 29.3899 10.4295 28.8 11.2779 28.8C11.7009 28.8 12.0449 28.9404 12.2714 29.2057C12.4983 29.4737 12.5885 29.8476 12.5234 30.2598ZM21.0379 27.3598H22.5352C22.7449 27.3598 22.8672 27.5949 22.7481 27.7669L17.7685 34.9547C17.6878 35.0712 17.555 35.1404 17.4131 35.1404H15.9177C15.7072 35.1404 15.5842 34.9034 15.7061 34.7309L17.2567 32.5423L15.6076 27.7027C15.5506 27.5345 15.6748 27.3598 15.8537 27.3598H17.3249C17.516 27.3598 17.6847 27.4852 17.7398 27.6682L18.615 30.5911L20.6801 27.5495C20.7609 27.4307 20.8952 27.3598 21.0379 27.3598Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M37.2708 32.8248L38.5476 24.7008C38.5672 24.5746 38.6765 24.4818 38.8034 24.4814H40.2409C40.3996 24.4814 40.5215 24.6237 40.4965 24.7812L39.2373 32.7589C39.2045 32.9694 39.0234 33.1241 38.8102 33.1241H37.5264C37.3676 33.1241 37.2457 32.9822 37.2708 32.8248ZM27.492 24.4818H24.3802C24.1678 24.4818 23.9867 24.6365 23.9535 24.8466L22.6952 32.8248C22.6701 32.9822 22.792 33.1241 22.951 33.1241H24.5476C24.696 33.1241 24.823 33.0159 24.8461 32.8686L25.2032 30.6071C25.236 30.3963 25.4175 30.2418 25.6299 30.2418H26.6143C28.6642 30.2418 29.8468 29.25 30.156 27.2848C30.2956 26.425 30.1615 25.7494 29.759 25.2761C29.3171 24.7568 28.5333 24.4818 27.492 24.4818ZM27.851 27.3957C27.6813 28.5121 26.8282 28.5121 26.003 28.5121H25.5339L25.8638 26.4271C25.8834 26.3012 25.9916 26.2083 26.1194 26.2083H26.3345C26.896 26.2083 27.4265 26.2083 27.7002 26.5285C27.8636 26.7197 27.9133 27.0035 27.851 27.3957ZM36.7914 27.3599H35.3026C35.1746 27.3599 35.0659 27.4528 35.0468 27.5789L34.9809 27.9955L34.8763 27.8445C34.5537 27.3763 33.835 27.2199 33.1171 27.2199C31.4706 27.2199 30.0649 28.4669 29.791 30.2161C29.6491 31.0886 29.8506 31.9229 30.3455 32.5048C30.8005 33.0397 31.4493 33.2627 32.2221 33.2627C33.5487 33.2627 34.2842 32.4096 34.2842 32.4096L34.2179 32.8237C34.1929 32.982 34.3148 33.1239 34.4746 33.1239H35.816C36.0285 33.1239 36.2095 32.9692 36.2428 32.7587L37.0485 27.6593C37.0731 27.5024 36.9512 27.3599 36.7914 27.3599ZM34.7148 30.2597C34.5718 31.1109 33.8954 31.6822 33.0337 31.6822C32.6019 31.6822 32.2554 31.5435 32.0333 31.2806C31.8131 31.0195 31.7303 30.6477 31.7996 30.2338C31.9346 29.3898 32.6207 28.7999 33.4691 28.7999C33.8922 28.7999 34.236 28.9403 34.4628 29.2056C34.6906 29.4736 34.7807 29.8475 34.7148 30.2597Z"
        fill="#179BD7"
      />
      <path
        d="M16.8143 21.6277L17.1968 19.1983L16.3448 19.1785H12.2764L15.1037 1.25081C15.1124 1.19668 15.141 1.14622 15.1827 1.11039C15.2244 1.07456 15.2778 1.05481 15.3334 1.05481H22.1934C24.4709 1.05481 26.0425 1.52873 26.8631 2.46412C27.2478 2.90294 27.4928 3.36149 27.6114 3.86612C27.7356 4.39564 27.7379 5.02825 27.6164 5.79983L27.6076 5.85615V6.35053L27.9924 6.56849C28.3164 6.74034 28.5737 6.93708 28.7711 7.16234C29.1004 7.53752 29.3133 8.01436 29.4031 8.57969C29.496 9.16112 29.4653 9.85298 29.3133 10.6363C29.1377 11.5373 28.8539 12.322 28.4707 12.9642C28.1181 13.5558 27.669 14.0465 27.136 14.4269C26.6269 14.7881 26.0221 15.0625 25.3383 15.238C24.6756 15.4105 23.9202 15.4976 23.0915 15.4976H22.5577C22.176 15.4976 21.805 15.6351 21.5141 15.8816C21.2223 16.1332 21.029 16.4768 20.9699 16.8528L20.9297 17.0715L20.2539 21.3534L20.2231 21.5107C20.2151 21.5606 20.2012 21.5854 20.1808 21.6023C20.1625 21.6174 20.1362 21.6277 20.1105 21.6277H16.8143Z"
        fill="white"
      />
      <path
        d="M28.3563 5.91339C28.3358 6.0443 28.3125 6.17813 28.2862 6.31562C27.3813 10.9605 24.2864 12.565 20.3333 12.565H18.3207C17.8373 12.565 17.4299 12.9161 17.3545 13.3929L16.324 19.9284L16.0322 21.7808C15.9832 22.0938 16.2247 22.3762 16.5404 22.3762H20.1103C20.5331 22.3762 20.892 22.069 20.9586 21.6522L20.9937 21.4707L21.6659 17.2054L21.7091 16.9715C21.7748 16.5532 22.1346 16.246 22.5573 16.246H23.0912C26.5497 16.246 29.2573 14.8418 30.0487 10.7783C30.3792 9.08087 30.2081 7.66352 29.3333 6.66668C29.0687 6.36609 28.7403 6.1167 28.3563 5.91339Z"
        fill="#179BD7"
      />
      <path
        d="M27.4099 5.53633C27.2718 5.49609 27.129 5.45953 26.9827 5.42662C26.8358 5.39445 26.685 5.36593 26.5301 5.34106C25.9874 5.25329 25.3928 5.21161 24.7558 5.21161H19.3789C19.2465 5.21161 19.1208 5.24159 19.0082 5.29571C18.7602 5.41491 18.576 5.64969 18.5313 5.93711L17.3873 13.1819L17.3545 13.3932C17.4299 12.9164 17.8372 12.5654 18.3206 12.5654H20.3332C24.2863 12.5654 27.3815 10.96 28.2861 6.31595C28.313 6.17845 28.3358 6.04462 28.3562 5.91372C28.1274 5.7923 27.8794 5.68845 27.6124 5.59995C27.5467 5.57801 27.4787 5.55681 27.4099 5.53633Z"
        fill="#222D65"
      />
      <path
        d="M18.5311 5.93696C18.5758 5.64953 18.7602 5.41477 19.008 5.29629C19.1215 5.24216 19.2465 5.21218 19.3787 5.21218H24.7558C25.3928 5.21218 25.9874 5.25387 26.5299 5.34163C26.685 5.3665 26.8358 5.39502 26.9827 5.42719C27.129 5.46012 27.2716 5.49669 27.4099 5.5369C27.4785 5.55738 27.5465 5.57858 27.6131 5.5998C27.88 5.68829 28.128 5.79288 28.3568 5.91355C28.6261 4.19706 28.3547 3.02837 27.4267 1.97009C26.4036 0.805054 24.5568 0.306274 22.1939 0.306274H15.3337C14.8509 0.306274 14.4393 0.657307 14.3646 1.13489L11.5073 19.2468C11.4509 19.6052 11.7273 19.9285 12.0886 19.9285H16.324L17.3873 13.1817L18.5311 5.93696Z"
        fill="white"
      />
    </svg>
  );
};

export default Paypal;
