import React from "react";
import Bitcoin from "assets/svg/Bitcoin";
import Visa from "assets/svg/Visa";
import Ethelium from "assets/svg/Ethelium";
import MasterCard from "assets/svg/MasterCard";
import Stripe from "assets/svg/Stripe";
import Paypal from "assets/svg/Paypal";
import { RxCross2 } from "react-icons/rx";

import "./popup.css";
const PresalePopup = ({ setShowPopup }) => {
  return (
    <div className="fixed ] inset-0 flex items-center justify-center bg-black bg-opacity-65 z-50 ">
      <div className="bg-gray-800  text-white rounded-xl p-6  relative w-[918px] border-2 border-[rgba(177,177,177,0.7)] ">
        <button
          className="absolute flex items-center justify-center top-3 right-3 text-white text-xl bg-[#5D78B7] rounded-full w-[38px] h-[38px]"
          onClick={() => setShowPopup(false)}
        >
          <RxCross2 fontSize="26px" />
        </button>
        <h2 className=" font-[redhatdisplay-b] text-[30px] text-center">
          Pre-Sale Live:
        </h2>
        <p className="text-2xl font-bold text-center mt-2">
          Only $0.01 Per EVRY Coin!
        </p>
        <p className=" font-[redhatdisplay-r] text-[20px] text-center mt-[190px]">
          Limited Time Offer – Introductory Price
        </p>
        <div className="flex items-center justify-center mt-6">
          <button className="relative   bg  py-5 px-14 rounded-full shadow-lg hover:scale-105 transition-transform">
            <span className="absolute inset-0 rounded-full disabled:opacity-50 bg-gradient-to-r from-[#00DDFF] to-[#4093FF] hover:brightness-110 text-[#f5f5f5]   blur-xl opacity-50"></span>
            <span className="relative z-10 font-[redhatdisplay-b] text-[23px]">
              Buy Now!
            </span>
          </button>
        </div>
        <div className="flex justify-center items-center gap-3 mt-6">
          <div className="w-[70px] h-[48px] bg-[#373C4B] border-2 flex items-center justify-center rounded-md  border-[#4F5A7B] ">
            <Visa />
          </div>
          <div className="w-[70px] h-[48px] bg-[#373C4B] border-2 flex items-center justify-center rounded-md  border-[#4F5A7B] ">
            <MasterCard />
          </div>
          <div className="w-[70px] h-[48px] bg-[#373C4B] border-2 flex items-center justify-center rounded-md  border-[#4F5A7B] ">
            <Paypal />
          </div>
          <div className="w-[70px] h-[48px] bg-[#373C4B] border-2 flex items-center justify-center rounded-md  border-[#4F5A7B] ">
            <Bitcoin />
          </div>
          <div className="w-[70px] h-[48px] bg-[#373C4B] border-2 flex items-center justify-center rounded-md  border-[#4F5A7B] ">
            <Stripe />
          </div>
          <div className="w-[70px] h-[48px] bg-[#373C4B] border-2 flex items-center justify-center rounded-md  border-[#4F5A7B] ">
            <Ethelium />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PresalePopup;
