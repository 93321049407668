import React from "react";

const MasterCard = () => {
  return (
    <svg
      width="46"
      height="28"
      viewBox="0 0 46 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23 24.3139C20.6169 26.3704 17.5255 27.6119 14.1475 27.6119C6.61021 27.6119 0.5 21.4308 0.5 13.806C0.5 6.18114 6.61021 0 14.1475 0C17.5255 0 20.6169 1.2415 23 3.29806C25.3831 1.2415 28.4745 0 31.8525 0C39.3898 0 45.5 6.18114 45.5 13.806C45.5 21.4308 39.3898 27.6119 31.8525 27.6119C28.4745 27.6119 25.3831 26.3704 23 24.3139Z"
        fill="#ED0006"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23 24.3139C25.9344 21.7816 27.7951 18.0136 27.7951 13.806C27.7951 9.59833 25.9344 5.83033 23 3.29806C25.3831 1.2415 28.4745 0 31.8525 0C39.3898 0 45.5 6.18114 45.5 13.806C45.5 21.4308 39.3898 27.6119 31.8525 27.6119C28.4745 27.6119 25.3831 26.3704 23 24.3139Z"
        fill="#F9A000"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.0001 3.2981C25.9345 5.83036 27.7951 9.59835 27.7951 13.806C27.7951 18.0136 25.9345 21.7815 23.0001 24.3138C20.0657 21.7815 18.2051 18.0136 18.2051 13.806C18.2051 9.59835 20.0657 5.83036 23.0001 3.2981Z"
        fill="#FF5E00"
      />
    </svg>
  );
};

export default MasterCard;
